import "./module/userAgentChecker.js"
import "./module/plugin.js"

import ScrollMagic from "scrollmagic";
var controller = new ScrollMagic.Controller();
if (window.matchMedia('(min-width: 834px)').matches) {
    var scene = new ScrollMagic.Scene({
        triggerElement: "#header",
        triggerHook: 'onLeave'
    })
    .setPin("#header")
    .addTo(controller);
}

//商品Slide
import Swiper from 'swiper/bundle';
var myswiper01 = new Swiper('.product__innerSingleFigSlider',{
  simulateTouch:  false
});

var $ = require('jquery');
$('.product__innerSingleFigThumBox').first().addClass('active');

// slideToメソッドを実行する関数を定義
$('.product__innerSingleFigThumBoxIn').on('click', function(){
  var elm = $(this).parent().index();
  myswiper01.slideTo(elm);
  $(this).parent().toggleClass('active');
  $('.product__innerSingleFigThumBoxIn').not(this).parent().removeClass('active');
});

//年確cookie
import "./module/jquery.cookie.js";
//cookieの値がonじゃなかったら表示させる
/*if ($.cookie('bnrRead') != 'on') {
    $('#movieLoading').addClass("appear");
}
//隠すボタンをクリックしたらバナーを隠す
$('#btn').click(function() {
    $('#movieLoading').remove();
    $.cookie('bnrRead', 'on', { //cookieにbnrReadという名前でonという値をセット
        expires: 1, //cookieの有効日数
        path:'/' //有効にするパス
    });
});*/

var cookie = $.cookie('ageLoading');
if(cookie){
    $('#ageLoading').addClass("close");
    $('#ageLoading').remove();
}
$('#btn').on('click',function(){
    $('#ageLoading').addClass("close");
    $('#ageLoading').remove();
    $.cookie('ageLoading','close', { //cookieにbnrReadという名前でonという値をセット
        expires: 1, //cookieの有効日数
        path:'/' //有効にするパス
    });
});

import objectFitImages from "object-fit-images";
objectFitImages();

require('picturefill');